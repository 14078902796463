import React, {
  useState,
  useLayoutEffect,
} from 'react';
import { useNavigate } from "react-router-dom";
import { on } from "devextreme/events";
import ListWidget from 'devextreme-react/list';
import List from "devextreme/ui/list";
import DataGrid from "devextreme/ui/data_grid";
import Button from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import LoadPanel from 'devextreme-react/load-panel';
import ActionSheet from 'devextreme-react/action-sheet';
import moment from 'moment';
import {
  DataGrid as DataGridWidget,
  Column,
  Editing,
  SortByGroupSummaryInfo,
  Selection,
} from 'devextreme-react/data-grid';
import { appConst } from '../../app-const';
import { useStaffListApiService } from '../../services';
import {
  showError,
  showSuccess,
} from '../../helpers/ui';
import { useScreenSize } from '../../utils/media-query';
import { useGlobalState } from '../../contexts/global-state';
import { usePageTitle } from '../../contexts/page-title';
import '../../components/component.scss';
import './staff-list.scss';

export default function ({ schoolId }) {
  const navigate = useNavigate();
  const {
    updateStaffListCurrentDateAsync,
    requestSchoolAssociationRemovalAsync,
    getStaffListBySchoolAsync,
  } = useStaffListApiService();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const { isXSmall, isSmall } = useScreenSize();
  const showList = isXSmall || isSmall;
  const {
    setSelectedMembers,
    selectedMembers,
    selectedSchool,
    setSelectedSchool,
    managedSchools,
    setManagedSchools,
    defaultSuggestedSAStartAndEndDates,
  } = useGlobalState();
  const { setTitle } = usePageTitle();
  const [showRemoveMemberPopup, setShowRemoveMemberPopup] = useState(false);
  const [showLoadingPanel, setShowLoadingPanel] = useState(false);

  const actionSheetItems = [
    { text: 'Select All Staff', id: 1 },
    { text: 'Unselect All Staff', id: 2 },
    { text: 'Request Selected Staff To Review Their Records', id: 3 },
    { text: 'Remove Selected Staff From List', id: 4 },
    { text: 'Add Members To Join Staff List', id: 5 },
    { text: 'This Staff List Is Up To Date', id: 6, type: "default" },
    { text: 'Cancel', id: 7 },
  ];

  useLayoutEffect(() => {
    selectedSchool && setTitle(selectedSchool.name);
    window.addEventListener('resize', setHeight);
    setHeight();
    return () => window.removeEventListener('resize', setHeight);
  }, [
    selectedSchool,
    setTitle,
  ]);

  function renderRemoveMemberPopup() {
    return (
      <>
        <Popup
          visible={showRemoveMemberPopup}
          height={(isXSmall || isSmall) ? "100%" : "50%"}
          width={(isXSmall || isSmall) ? "100%" : "50%"}
          title="Staff to remove..."
          showCloseButton={true}
          onHiding={() => setShowRemoveMemberPopup(false)}
        >
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            visible={showLoadingPanel}
          />
          <DataGridWidget
            dataSource={selectedMembers}
            height="80%"
            columnHidingEnabled={true}
          >
            <Editing
              allowAdding={false}
              allowDeleting={true}
              allowUpdating={true}
            />
            <Column
              dataField="FullName"
              allowEditing={false}
            />
            <Column
              dataField="EndDate"
              dataType="date"
              format={appConst.dateDisplayFormat} />
          </DataGridWidget>
          <div style={{ display: 'flex', justifyContent: 'right', marginTop: '10px', gap: '10px' }}>
            <Button
              type='default'
              text="Process"
              onClick={async () => {
                setShowLoadingPanel(true);
                try {
                  await requestSchoolAssociationRemovalAsync(selectedSchool.schoolId,
                  selectedMembers.map(a => ({ Id: a.Id, EndDate: a.EndDate })));
                } catch (error) {
                  showError(error.message)
                  setShowLoadingPanel(false);
                  return;
                }

                const school = managedSchools.find(s => s.schoolId === parseInt(schoolId));
                school && (getStaffListBySchoolAsync(schoolId).then(r => {
                  school.staffList = r.data;
                  setSelectedSchool(school);
                  setShowRemoveMemberPopup(false);
                  setShowLoadingPanel(false);
                }));
              }}
            />
            <Button
              type="default"
              text="Cancel"
              onClick={() => { setShowRemoveMemberPopup(false) }}
            />
          </div>
        </Popup>
      </>
    );
  }
  function renderMember(item) {
    const className = item.hasHomeEmailAddress ? "home-email-badge-text-container-list home-email-badge-text-container-green" : "home-email-badge-text-container-list home-email-badge-text-container-red";
    const text = item.hasHomeEmailAddress ? "Present" : "Missing";
    return (
      <>
        <div className="item-container">
          <div>{item.fullName}</div>
          <div className="memberId">
            {`MemberId=${item.memberId}`}
          </div>
        </div>
        <div style={{ textAlign: 'left' }}>Record last reviewed {(item.recordReviewedByMember !== null) ? moment(new Date(item.recordReviewedByMember)).format('MMM D, YYYY') : '(never)'}</div>
        <div className="home-email-container-list">
          <p>Home Email</p>
          <div className={className}><span className="home-email-badge-text-list">{text}</span></div>
        </div>
      </>)
  }

  function listHasSelectedItems(listInstance) {
    let selectedRows;
    if (listInstance.NAME === 'dxDataGrid') {
      selectedRows = listInstance.getSelectedRowKeys();
    } else {
      selectedRows = listInstance.option("selectedItemKeys")
    }
    if (selectedRows.length === 0) {
      showError('No members are selected.')
      return false;
    }
    return true;
  }

  function saveSelectedMembersToGlobalState(listInstance) {
    const selectedMembers = (listInstance.NAME === 'dxDataGrid') ? listInstance.getSelectedRowsData() : listInstance.option("selectedItems");
    setSelectedMembers(selectedMembers);
  }

  function pushHistory(path, listInstance) {
    saveSelectedMembersToGlobalState(listInstance);
    navigate(path);
  }

  async function updateStaffListToCurrentDateAsync() {
    try {
      const r = await updateStaffListCurrentDateAsync(selectedSchool.schoolId);
      if (r.data) {
        const tmpSelectedSchool = { ...selectedSchool };
        tmpSelectedSchool.staffListReviewedByCommRep = moment(new Date()).format('yyyy-MM-DD hh:mm a')
        setSelectedSchool(tmpSelectedSchool);

        const index = managedSchools.findIndex(s => s.schoolId === selectedSchool.schoolId);
        const tmpManagedSchools = [...managedSchools];
        tmpManagedSchools[index] = tmpSelectedSchool;
        setManagedSchools(tmpManagedSchools);

        showSuccess('Thank you for ensuring the staff list is up to date!');
      } else {
        showError('There was an error updating the staff list review date.')
      }
    }
    catch (error) {
      showError(error.message);
    }
  }

  function renderList() {
    return (
      <>
        <ListWidget
          id="memberList"
          dataSource={selectedSchool.staffList}
          selectAllMode='allPages'
          showSelectionControls={true}
          selectionMode='all'
          searchExpr="fullName"
          searchEnabled={true}
          searchMode={'contains'}
          itemRender={renderMember}
          scrollingEnabled={true}
          useNativeScrolling={true}
          pageLoadMode="scrollBottom"
          showScrollbar={'always'}
          onContentReady={(e) => {
            //if (e.component.option('items').length > 0 && firstContentReady) {
            //  e.component.option('selectedItemKeys', selectedMemberKeys)
            //  firstContentReady = false;
            //}
            var itemContent = e.element.querySelectorAll(".dx-item-content");
            on(itemContent, "dxclick", function (event) {
              event.stopImmediatePropagation();
              saveSelectedMembersToGlobalState(e.component);
              let i = event.delegateTarget.innerHTML.indexOf("MemberId=") + "MemberId=".length;
              let j = event.delegateTarget.innerHTML.indexOf("<", i);
              let memberId = event.delegateTarget.innerHTML.substring(i, j);
              navigate(`/school/${schoolId}/${memberId}`);
            })
          }}
          onSelectionChanged={
            (e) => {
              const tmp = e.component.option("selectedItems");
              const ds = tmp && tmp.map(a => ({ Id: a.memberId, FullName: a.fullName, EndDate: defaultSuggestedSAStartAndEndDates.defaultEndDate }));
              setSelectedMembers(ds);
            }
          }
        />
        {renderRemoveMemberPopup()}
      </>
    );
  }

  function renderGrid() {
    return (
      <>
        <DataGridWidget
          id="memberGrid"
          keyExpr={["memberId", "schoolId"]}
          {...appConst.defaultGridOptions}
          dataSource={selectedSchool.staffList}
          onSelectionChanged={
            (e) => {
              const tmp = e.component.getSelectedRowsData();
              const ds = tmp && tmp.map(a => ({ Id: a.memberId, FullName: a.fullName, EndDate: defaultSuggestedSAStartAndEndDates.defaultEndDate, SchoolAssociationId: a.schoolAssociationId }));
              setSelectedMembers(ds);
            }
          }
        >
          <Selection
            mode="multiple"
            showCheckBoxesMode='always'
          />
          <Editing
            mode="popup"
            allowAdding={false}
            allowDeleting={false}
            allowUpdating={false}
          />
          <SortByGroupSummaryInfo
            summaryItem="count"
            sortOrder="desc"
          />
          <Column dataField="preferredFirstName" />
          <Column dataField="firstName" />
          <Column dataField="lastName" />
          <Column dataField="workEmailAddress" />
          <Column dataField="hasHomeEmailAddress" caption="Home Email"
            editCellRender={(cell) => {
              if (cell.rowType === 'data') {
                const className = cell.data.hasHomeEmailAddress ? "home-email-badge-text-container home-email-badge-text-container-green" : "home-email-badge-text-container home-email-badge-text-container-red";
                const text = cell.data.hasHomeEmailAddress ? "Present" : "Missing";

                return (<div className="home-email-badge-container"><div className={className}><span className="home-email-badge-text">{text}</span></div></div>)
              }
            }}
          />
          <Column dataField="division" />
          <Column dataField="recordReviewedByMember"
            dataType="datetime"
            format={appConst.dateAndTimeDisplayFormat}
          />
        </DataGridWidget>
        {renderRemoveMemberPopup()}
      </>
    )
  }

  function setHeight() {
    let resizeElement = document.getElementById("memberList");
    let resizeInstance;
    if (resizeElement) {
      resizeInstance = List.getInstance(resizeElement);
    } else {
      resizeElement = document.getElementById("memberGrid");
      if (resizeElement) {
        resizeInstance = DataGrid.getInstance(resizeElement);
      }
    }
    if (resizeInstance) {
      resizeInstance.option("height", window.innerHeight - resizeElement.getBoundingClientRect().y - 20);
    }
  }

  return ((selectedSchool && selectedSchool.staffList) ?
    <div className="staff-list-component">
      <div className="staff-list-header responsive-paddings">
        <div className="school-info-container">
          <div id="principalName">
            Principal: {selectedSchool && selectedSchool.principalName}
          </div>
          <div id="memberCount">
            Member Count: {selectedSchool && selectedSchool.staffList && selectedSchool.staffList.length}
          </div>
          <div id="lastReviewed">
            {`Last Reviewed: ${selectedSchool && moment(new Date(selectedSchool.staffListReviewedByCommRep)).format('yyyy-MM-DD hh:mm a')}`}
          </div>
        </div>
        <div className="action-button-container">
          <Button
            id="openActionListButton"
            className="overflow-button"
            text="Action"
            onClick={
              () => {
                setShowActionSheet(!showActionSheet);
              }
            }
          />
        </div>
      </div>
      <div className="list-container">
        {showList ? renderList() : renderGrid()}
      </div>
      <ActionSheet
        id="actionSheet"
        dataSource={actionSheetItems}
        visible={showActionSheet}
        showTitle={false}
        showCancelButton={false}
        onItemClick={
          async (e) => {
            setShowActionSheet(false);
            let action = e.itemData.id;
            let containerElement = showList ? document.getElementById("memberList") : document.getElementById("memberGrid");
            let containerInstance = (showList) ? List.getInstance(containerElement) : DataGrid.getInstance(containerElement);
            switch (action) {
              case 1:
                containerInstance.selectAll();
                break;
              case 2:
                if (containerInstance.NAME === 'dxList') {
                  containerInstance.option("selectedItemKeys", []);
                }
                else {
                  containerInstance.deselectAll();
                }
                break;
              case 3:
                if (!listHasSelectedItems(containerInstance)) return;
                pushHistory('review-records-request', containerInstance);
                break;
              case 4:
                if (!listHasSelectedItems(containerInstance)) return;
                setShowRemoveMemberPopup(true);
                break;
              case 5:
                pushHistory('add-staff-request', containerInstance);
                break;
              case 6:
                await updateStaffListToCurrentDateAsync();
                break;
              default:
                break;
            }
          }
        }
        onActionSheetCancelClick={
          (e) => {
            setShowActionSheet(false);
          }
        }
      />
    </div>
    : (<></>)
  );
}