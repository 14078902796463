import React, { useState } from 'react';
import { Button } from 'devextreme-react/button';
/* eslint-disable no-unused-vars */
import RadioGroup from 'devextreme-react/radio-group';
/* eslint-enable no-unused-vars */
import DxForm from "devextreme/ui/form";
import { Popup } from 'devextreme-react/popup';
import { confirm } from 'devextreme/ui/dialog';
import { Form, SimpleItem, GroupItem, Label, RequiredRule } from 'devextreme-react/form'
import { useGlobalState } from '../../contexts/global-state';
import { appConst } from '../../app-const';
import { Card } from './card/card';

import styles from './card-container.module.scss';

export default (props) => {
  const [addNewSAPopupVisible, setAddNewSAPopupVisible] = useState(false);
  const { data, userHasWorkLocation, addNewSA, updatePrinaryToFalse, showAddNewLocationButton, updateSADivision } = props;
  const [newSA, setNewSA] = useState({});
  const { lookupData } = useGlobalState();

  const schoolEditorOptions = {
    ...appConst.defaultSelectBoxOptions,
    items: lookupData.schools,
  };

  const divisionEditorOptions = {
    ...appConst.defaultSelectBoxOptions,
    items: lookupData.divisions,
  };

  const startDateEditorOptions = {
    ...appConst.defaultSelectDateBoxOptions,
  };

  const addNewSaFormId = "addNewSAForm";

  function showAddNewSAPopupVisible(id, selectedEndDate) {
    setNewSA({ ...newSA, saIndexToEnd: id, selectedEndDate: selectedEndDate, primary:true });
    setAddNewSAPopupVisible(true);
  }

  function renderAddNewSA() {
    return (
      <Popup
        visible={addNewSAPopupVisible}
        onHiding={() => {
          setAddNewSAPopupVisible(false);
        }}
        dragEnabled={false}
        hideOnOutsideClick={false}
        showCloseButton={false}
        showTitle={false}
        maxWidth={'400px'}
        height={'auto'}
      >
        <Form
          id={addNewSaFormId}
          formData={newSA}
        >
          <GroupItem colCount={1} caption="Add new work location...">
            <SimpleItem
              dataField="schoolId"
              editorType="dxSelectBox"
              editorOptions={schoolEditorOptions}
            >
              <Label text='School' />
              <RequiredRule message="School name is required" />
            </SimpleItem>
            <SimpleItem
              visible={userHasWorkLocation && !Object.hasOwn(newSA, 'saIndexToEnd')}
              dataField="primary"
              editorType="dxRadioGroup"
              editorOptions={{
                displayExpr: 'text',
                valueExpr: 'value',
                layout: 'horizontal',
                dataSource: [{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }],
              }}
            >
              <Label text="Is this your main work location?" />
              <RequiredRule message="Please select if this work location is your main one." />
            </SimpleItem>
            <SimpleItem
              editorType="dxDateBox"
              dataField="startDate"
              editorOptions={startDateEditorOptions}>
              <RequiredRule message="Start date is required" />
              <Label text="Start Date (YYYY-MM-DD)" />
            </SimpleItem>
            <SimpleItem
              editorType="dxSelectBox"
              dataField="divisionId"
              editorOptions={divisionEditorOptions}
            >
              <Label text='Division (for local election purposes)' />
            </SimpleItem>
            <GroupItem colCount={2}>
            <SimpleItem
              name="okButton"
              itemType="button"
              horizontalAlignment="center"
              verticalAlignment="bottom"
              buttonOptions={
                {
                  type: 'default',
                  text: 'Ok',
                  width:'100%',
                  onClick: async (e) => {
                    let formEl = document.getElementById(addNewSaFormId);
                    let form = DxForm.getInstance(formEl);
                    const v = form.validate().isValid;
                    if (v) {
                      const schoolName = form.getEditor('schoolId').option('text');
                      newSA.schoolName = schoolName;
                      if (!userHasWorkLocation) newSA.primary = true;

                      const addNew = () => {
                        addNewSA(newSA);
                        form.resetValues();
                        setAddNewSAPopupVisible(false);
                      };

                      if (newSA.primary && userHasWorkLocation) {
                        const result = confirm(`<i>If you change your main work location to ${schoolName}, this will become the location at which you receive member communications.</i>`, "Confirm changes");
                        result.then(async (dialogResult) => {
                          if (dialogResult) {
                            addNew();
                          }
                        });
                      } else {
                        addNew();
                      }
                    }
                  }
                }
              } />
            <SimpleItem
              name="cancelButton"
              itemType="button"
              horizontalAlignment="center"
              verticalAlignment="bottom"
              buttonOptions={
                {
                  type: 'default',
                  text: 'Cancel',
                  width:'100%',
                  onClick: async (e) => {
                    let formEl = document.getElementById(addNewSaFormId);
                    let form = DxForm.getInstance(formEl);
                    form.resetValues();
                    setAddNewSAPopupVisible(false);
                  }
                }
              } />
              </GroupItem>
          </GroupItem>
        </Form>
      </Popup>
    );
  }

  return (
    <>
    <div className={styles['card-container']}>
        {data && data.map((cardData, index) => {
          cardData.index = index;
          return <Card
            key={index}
            data={cardData}
            updatePrimaryToFalse={updatePrinaryToFalse}
            updateSADivision={updateSADivision}
            updateSAStartDate={props.updateSAStartDate}
            updatePrinaryToTrue={props.updatePrinaryToTrue}
            index={index}
            schools={data}
            showAddNewSAPopupVisible={showAddNewSAPopupVisible} />;
      })}
      </div>
      <div className={styles['add-new-sa-container']}>
        <Button
          text="Add Another Work Location"
          icon="fa-sharp fa-solid fa-plus"
          visible={showAddNewLocationButton}
          onClick={() => {
            setAddNewSAPopupVisible(true);
          }}
        />
      </div>
      {renderAddNewSA()}
    </>
  );
}
